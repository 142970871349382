/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    a: "a",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Hosting"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://appliku.com/"
  }, "Appliku - Django in minutes")), "\n", React.createElement(_components.p, null, "Free tier with the following features: 1 Server, 1 App, Unlimited Deployments, Community Support"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dev.to/kostjapalovic/tired-of-deployments-built-my-own-heroku-47ed"
  }, "Appliku platform story")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
